import React, { useEffect, useState } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";

import "moment/locale/de";

const localizer = momentLocalizer(moment);
moment.locale("de");

const messages = {
  today: "Heute",
  next: "Nächster",
  previous: "Vorheriger",
};

const host = process.env.REACT_APP_BACKEND_HOST;
const port = process.env.REACT_APP_BACKEND_PORT;
const protocol = host==="localhost"?"http://":"https://"
const currentHost = `${protocol}${host}:${port}`

const HüttenBuchungKalenderAdmin = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [name, setName] = useState("");
  const [startTag, setStartTag] = useState("");
  const [endTag, setEndTag] = useState("");
  const [email, setEmail] = useState("");
  const [telefonnummer, setTelefonnummer] = useState("");
  const [idAktualisieren, setIdAktualisieren] = useState("");
  const [nameAktualisieren, setNameAktualisieren] = useState("");
  const [startTagAktualisieren, setStartTagAktualisieren] = useState("");
  const [endTagAktualisieren, setEndTagAktualisieren] = useState("");
  const [emailAktualisieren, setEmailAktualisieren] = useState("");
  const [telefonnummerAktualisieren, setTelefonnummerAktualisieren] =
    useState("");
  const [error, setError] = useState(false);
  const [errorAktualisieren, setErrorAktualisieren] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const CustomToolbar = ({ label, onNavigate }) => (
    <div className="px-4 py-2 bg-gray-200">
      <div>
        <span className="text-gray-700">{label}</span>
      </div>
      <div className="flex justify-between items-center px-4 py-2 bg-gray-200">
        <button
          className="text-gray-700 font-bold"
          onClick={() => onNavigate("PREV")}
        >
          {"<"}
        </button>
        <button
          className="text-gray-700 font-bold"
          onClick={() => onNavigate("TODAY")}
        >
          Heute
        </button>
        <button
          className="text-gray-700 font-bold"
          onClick={() => onNavigate("NEXT")}
        >
          {">"}
        </button>
      </div>
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "" || startTag === "" || endTag === "") {
      setError(true);
      return;
    }

    try {
      const hüttenToken = localStorage.getItem("hüttenToken");
      const axiosInstance = axios.create({
        baseURL: currentHost,
        headers: {
          common: {
            Authorization: hüttenToken,
          },
        },
      });

      const response = await axiosInstance.post("/api/newEvent", {
        name: name,
        startTag: startTag,
        endTag: endTag,
        email: email,
        telefonnummer: telefonnummer,
      });

      console.log(response.data); // Erfolgsnachricht oder Rückgabewert ausgeben

      // Zurücksetzen der Eingabefelder nach dem Eintragen
      setName("");
      setStartTag("");
      setEndTag("");
      setEmail("");
      setTelefonnummer("");
    } catch (error) {
      console.error("Fehler beim Hinzufügen des Events:", error);
    }
    refresh();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (
      nameAktualisieren === "" ||
      startTagAktualisieren === "" ||
      endTagAktualisieren === ""
    ) {
      setErrorAktualisieren(true);
      return;
    }

    try {
      const hüttenToken = localStorage.getItem("hüttenToken");
      const axiosInstance = axios.create({
        baseURL: currentHost,
        headers: {
          common: {
            Authorization: hüttenToken,
          },
        },
      });

      const response = await axiosInstance.post("/api/updateEvent", {
        id: idAktualisieren, // ID des ausgewählten Events
        name: nameAktualisieren,
        startTag: startTagAktualisieren,
        endTag: endTagAktualisieren,
        email: emailAktualisieren,
        telefonnummer: telefonnummerAktualisieren,
      });

      console.log(response.data); // Erfolgsnachricht oder Rückgabewert ausgeben

      // Zurücksetzen der Eingabefelder nach dem Aktualisieren
      setSelectedEvent(null);
      setNameAktualisieren("");
      setStartTagAktualisieren("");
      setEndTagAktualisieren("");
      setEmailAktualisieren("");
      setTelefonnummerAktualisieren("");
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Events:", error);
    }
    refresh();
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const hüttenToken = localStorage.getItem("hüttenToken");
      const headers = {
        Authorization: hüttenToken,
      };
      const response = await fetch(`${currentHost}/api/getEvents`, {
        method: "GET",
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const formatEventData = (events) => {
    return events.map((event) => {
      const endErhöht = new Date(event.endTag);
      endErhöht.setDate(endErhöht.getDate() + 1);

      return {
        id: event.id,
        title: event.name,
        start: new Date(event.startTag),
        end: new Date(event.endTag),
        endErhöht: endErhöht,
        email: event.email,
        telefonnummer: event.telefonnummer,
      };
    });
  };

  const refresh = () => {
    fetchEvents();
    formatEventData(events);
    //window.location.reload();
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);

    setIdAktualisieren(event.id);
    setNameAktualisieren(event.title);
    setStartTagAktualisieren(moment(event.start).format("YYYY-MM-DD"));
    setEndTagAktualisieren(moment(event.end).format("YYYY-MM-DD"));
    setEmailAktualisieren(event.email);
    setTelefonnummerAktualisieren(event.telefonnummer);
  };

  const handleCancel = () => {
    setSelectedEvent(null);
    setNameAktualisieren("");
    setStartTagAktualisieren("");
    setEndTagAktualisieren("");
    setEmailAktualisieren("");
    setTelefonnummerAktualisieren("");
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    if (
      nameAktualisieren === "" ||
      startTagAktualisieren === "" ||
      endTagAktualisieren === ""
    ) {
      setErrorAktualisieren(true);
      return;
    }

    try {
      const hüttenToken = localStorage.getItem("hüttenToken");
      const axiosInstance = axios.create({
        baseURL: currentHost,
        headers: {
          common: {
            Authorization: hüttenToken,
          },
        },
      });

      const response = await axiosInstance.post(currentHost + "/api/deleteEvent",
        {
          id: idAktualisieren,
        }
      );

      console.log(response.data); // Erfolgsnachricht oder Rückgabewert ausgeben

      // Zurücksetzen der Eingabefelder nach dem Aktualisieren
      setSelectedEvent(null);
      setNameAktualisieren("");
      setStartTagAktualisieren("");
      setEndTagAktualisieren("");
      setEmailAktualisieren("");
      setTelefonnummerAktualisieren("");
    } catch (error) {
      console.error("Fehler beim Aktualisieren des Events:", error);
    }
    refresh();
  };

  const toggleForm = () => {
    if (showForm) {
      setName("");
      setStartTag("");
      setEndTag("");
      setEmail("");
      setTelefonnummer("");
    }
    setShowForm(!showForm);
  };

  const formattedEvents = formatEventData(events);

  return (
    <div class="mt-10 mx-5 lg:mx-32">
      <div>
        <div style={{ height: "500px" }} class="z-0">
          <Calendar
            localizer={localizer}
            events={formattedEvents}
            views={["month"]}
            startAccessor="start"
            endAccessor="endErhöht"
            onSelectEvent={handleSelectEvent}
            messages={messages}
            components={{ toolbar: CustomToolbar }}
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: "#0a2b03",
                color: "#F7da0e",
              },
            })}
          />
        </div>

        {selectedEvent && (
          <div className="my-10">
            {isModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-gray-400 rounded p-4">
                  <h3 className="text-lg font-bold mb-2">Buchung löschen</h3>
                  <p>Sicher, dass du die Buchung löschen willst?</p>
                  <p>Name: {nameAktualisieren}</p>
                  <p>von: {startTagAktualisieren}</p>
                  <p>bis: {endTagAktualisieren}</p>

                  <div className="flex justify-between mt-4">
                    <button
                      onClick={closeModal}
                      className="bg-green-600 hover:bg-green-700 px-4 py-2 rounded"
                    >
                      Abbrechen
                    </button>
                    <button
                      onClick={closeModal && handleDelete}
                      className="bg-red-600 hover:bg-red-700 px-4 py-2 rounded"
                    >
                      Löschen
                    </button>
                  </div>
                </div>
              </div>
            )}
            <form onSubmit={handleUpdate} className="w-full max-w-sm mx-auto">
              <div className="mb-4">
                <label
                  htmlFor="nameAktualisieren"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name:
                </label>
                <input
                  type="text"
                  id="nameAktualisieren"
                  value={nameAktualisieren}
                  onChange={(e) => setNameAktualisieren(e.target.value)}
                  className={`mt-1 px-3 py-2 w-full border ${
                    errorAktualisieren && nameAktualisieren === ""
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="startTagAktualisieren"
                  className="block text-sm font-medium text-gray-700"
                >
                  Startdatum:
                </label>
                <input
                  type="date"
                  id="startTagAktualisieren"
                  value={startTagAktualisieren}
                  onChange={(e) => setStartTagAktualisieren(e.target.value)}
                  className={`mt-1 px-3 py-2 w-full border ${
                    errorAktualisieren && startTagAktualisieren === ""
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="endTagAktualisieren"
                  className="block text-sm font-medium text-gray-700"
                >
                  Enddatum:
                </label>
                <input
                  type="date"
                  id="endTagAktualisieren"
                  value={endTagAktualisieren}
                  onChange={(e) => setEndTagAktualisieren(e.target.value)}
                  className={`mt-1 px-3 py-2 w-full border ${
                    errorAktualisieren && endTagAktualisieren === ""
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="emailAktualisieren"
                  className="block text-sm font-medium text-gray-700"
                >
                  email:
                </label>
                <input
                  type="text"
                  id="emailAktualisieren"
                  value={emailAktualisieren}
                  onChange={(e) => setEmailAktualisieren(e.target.value)}
                  className="mt-1 px-3 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="telefonnummerAktualisieren"
                  className="block text-sm font-medium text-gray-700"
                >
                  telefonnummer:
                </label>
                <input
                  type="text"
                  id="telefonnummerAktualisieren"
                  value={telefonnummerAktualisieren}
                  onChange={(e) =>
                    setTelefonnummerAktualisieren(e.target.value)
                  }
                  className="mt-1 px-3 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              {errorAktualisieren && (
                <p className="text-red-500 text-sm mb-4">
                  Bitte füllen Sie alle erforderlichen Felder aus.
                </p>
              )}
              <div className="text-center">
                <button
                  type="submit"
                  className="inline-block bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-blue-500 focus:ring-offset-2 focus:ring-2"
                >
                  Buchung aktualisieren
                </button>
                <div class="text-center mt-4">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="mr-10 inline-block bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-red-500 focus:ring-offset-2 focus:ring-2"
                  >
                    Abbrechen
                  </button>

                  <button
                    type="button"
                    onClick={openModal}
                    className="inline-block bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring-red-500 focus:ring-offset-2 focus:ring-2"
                  >
                    Löschen
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}

        {!selectedEvent && (
          <div class="my-10">
            {showForm && (
              <form
                onSubmit={handleSubmit}
                className="w-full max-w-sm mx-auto mt-5"
              >
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={`mt-1 px-3 py-2 w-full border ${
                      error && name === ""
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="startTag"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Startdatum:
                  </label>
                  <input
                    type="date"
                    id="startTag"
                    value={startTag}
                    onChange={(e) => setStartTag(e.target.value)}
                    className={`mt-1 px-3 py-2 w-full border ${
                      error && startTag === ""
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="endTag"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Enddatum:
                  </label>
                  <input
                    type="date"
                    id="endTag"
                    value={endTag}
                    onChange={(e) => setEndTag(e.target.value)}
                    className={`mt-1 px-3 py-2 w-full border ${
                      error && endTag === ""
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    email:
                  </label>
                  <input
                    type="text"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 px-3 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="telefonnummer"
                    className="block text-sm font-medium text-gray-700"
                  >
                    telefonnummer:
                  </label>
                  <input
                    type="text"
                    id="telefonnummer"
                    value={telefonnummer}
                    onChange={(e) => setTelefonnummer(e.target.value)}
                    className="mt-1 px-3 py-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                {error && (
                  <p className="text-red-500 text-sm mb-4">
                    Bitte füllen Sie alle erforderlichen Felder aus.
                  </p>
                )}
                <div className="text-center">
                  <button
                    type="submit"
                    className="inline-block bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-blue-500 focus:ring-offset-2 focus:ring-2"
                  >
                    Buchung hinzufügen
                  </button>
                </div>
              </form>
            )}
            <button
              onClick={toggleForm}
              className={`mt-5 px-4 py-2 rounded-md focus:outline-none focus:ring-blue-500 focus:ring-offset-2 focus:ring-2 ${
                showForm
                  ? "bg-red-500 text-white hover:bg-red-600"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
            >
              {showForm ? "Abbrechen" : "Neue Buchung"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HüttenBuchungKalenderAdmin;
