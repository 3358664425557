import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Routes } from 'react-router-dom';

import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from './components/Header';
import Kontakt from './routes/kontakt';
import PageNotFound from './routes/PageNotFound';
import './App.css';

import HüttenBuchung from './routes/hüttenBuchung';


export const changeLocation = (newLocation: string) => {
  window.location.hash = newLocation;

}

const routes = (
  <Routes>
    <Route path="/" Component={HüttenBuchung} />
    <Route path="/kontakt" Component={Kontakt} />
    <Route Component={PageNotFound} />
  </Routes>
)


ReactDOM.render(
  <div>
    <HashRouter>
      <Header />
      <React.StrictMode>
        {routes}
      </React.StrictMode>,
    </HashRouter>
  </div>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
