import React, { useState } from "react";
const CookieNotification = () => {
  const [showNotification, setShowNotification] = useState(true);
  const handleCloseNotification = () => {
    setShowNotification(false);
  };
  return (
    showNotification && (
      <div className="fixed bottom-0 left-0 w-full p-2 bg-gray-800 text-white text-center">
        {" "}
        <p>This website uses cookies to improve your experience.</p>{" "}
        <button
          className="bg-gray-300 hover:bg-Ae-grün text-Ae-grün hover:text-Ae-gold transition-all duration-300 font-semibold px-4 py-2 rounded-full mx-auto mt-2"
          onClick={handleCloseNotification}
        >
          {" "}
          Accept{" "}
        </button>{" "}
      </div>
    )
  );
};
export default CookieNotification;
