import React, { useState, useEffect } from "react";
import axios from "axios";

import HüttenBuchungKalenderAdmin from "../components/HütteKalenderAdmin";
import HüttenBuchungKalenderUser from "../components/HütteKalenderUser";
import HüttenLogin from "../components/HüttenLogin";
import useLocalStorage from "../components/useLocalStorage";
import CookieNotification from "../components/Cookie";

const host = process.env.REACT_APP_BACKEND_HOST;
const port = process.env.REACT_APP_BACKEND_PORT;
const protocol = host==="localhost"?"http://":"https://"
const currentHost = `${protocol}${host}:${port}`

function HüttenBuchung() {
  const [accessLevel, setAccessLevel] = useState("no-token");
  let hüttenToken = useLocalStorage("hüttenToken");

  const checkToken = () => {
    console.log("checking Token...");
    hüttenToken = localStorage.getItem("hüttenToken");

    if (!hüttenToken) {
      setAccessLevel("no-token");
      console.log("no Token!");
      return;
    }

    axios
      .get(currentHost + "/api/checkToken", {
        headers: {
          Authorization: hüttenToken,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const newToken = response.data.newToken;
          const userRole = response.data.role;

          localStorage.setItem("hüttenToken", newToken);
          localStorage.setItem("role", userRole);
          setAccessLevel(userRole);
        } else {
          localStorage.removeItem("hüttenToken");
          setAccessLevel("no-token");
        }
      })
      .catch((error) => {
        console.error("error in token check");
        setAccessLevel("no-token");
      });
  };

  useEffect(() => {
    checkToken();
  }, [hüttenToken]);

  const handleSuccessfulLogin = () => {
    checkToken();
  };

  let viewComponent;

  switch (accessLevel) {
    case "user":
      viewComponent = <HüttenBuchungKalenderUser />;
      break;
    case "admin":
      viewComponent = <HüttenBuchungKalenderAdmin />;
      break;
    default:
      viewComponent = <HüttenLogin onLoginSuccess={handleSuccessfulLogin} />;
  }

  return (
    <div class="text-center my-10 mx-5">
      {viewComponent}
      <CookieNotification />
    </div>
  );
}

export default HüttenBuchung;
