import React, { useEffect, useState } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "moment/locale/de";

const localizer = momentLocalizer(moment);
moment.locale("de");

const host = process.env.REACT_APP_BACKEND_HOST;
const port = process.env.REACT_APP_BACKEND_PORT;
const protocol = host==="localhost"?"http://":"https://"
const currentHost = `${protocol}${host}:${port}`

const HüttenBuchungKalenderUser = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const hüttenToken = localStorage.getItem("hüttenToken");
      const headers = {
        Authorization: hüttenToken,
      };
      const response = await fetch(`${currentHost}/api/getEventsUser`, {
        method: "GET",
        headers,
      });
      console.log(response);
      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const formatEventData = (events) => {
    return events.map((event) => {
      const endErhöht = new Date(event.endTag);
      endErhöht.setDate(endErhöht.getDate() + 1);

      return {
        start: new Date(event.startTag),
        end: new Date(event.endTag),
        endErhöht: endErhöht,
      };
    });
  };

  const formattedEvents = formatEventData(events);

  const CustomToolbar = ({ label, onNavigate }) => (
    <div className="px-4 py-2 bg-gray-200">
      <div>
        <span className="text-gray-700">{label}</span>
      </div>
      <div className="flex justify-between items-center px-4 py-2 bg-gray-200">
        <button
          className="text-gray-700 font-bold"
          onClick={() => onNavigate('PREV')}
        >
          {'<'}
        </button>
        <button
          className="text-gray-700 font-bold"
          onClick={() => onNavigate('TODAY')}
        >
          Heute
        </button>
        <button
          className="text-gray-700 font-bold"
          onClick={() => onNavigate('NEXT')}
        >
          {'>'}
        </button>

      </div>
    </div>
  );

  return (
    <div class="mt-10 mx-5 lg:mx-32">
      <div style={{ height: "500px" }}>
        <Calendar
          localizer={localizer}
          events={formattedEvents}
          views={["month"]}
          startAccessor="start"
          endAccessor="endErhöht"
          components={{ toolbar: CustomToolbar, }}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: "#0a2b03",
              borderRadius: "5px",
              border: "none",
              padding: "10px",
              margin: "0",
              height: "30px", // Ändern Sie die Höhe des Balkens hier
              fontSize: "14px",
              fontWeight: "bold",
              cursor: "pointer",
            },
          })}
        />
      </div>
    </div>
  );
};

export default HüttenBuchungKalenderUser;
