function Kontakt() {
  return (
    <div class="text-center mt-10 mx-5 lg:text-lg lg:mx-32">
      <div class="text-4xl text-Ae-grün font-bold">Kontakt & Anfahrt</div>
      <div class="mt-10 font-bold">Postanschrift:</div>
      <div>e.h.v. K.D.St.V. Aenania</div>
      <div>Türkenstraße 38</div>
      <div>80799 München</div>
      <div class="mt-10 font-bold">Kontaktdaten:</div>
      <div class="mt-5">
        <a
          href="mailto:senior@aenania.de"
          class="text-lg bg-gray-300 hover:bg-Ae-grün text-Ae-grün hover:text-Ae-gold rounded font-bold py-2 px-4"
        >
          senior(at)aenania.de
        </a>
      </div>
      <div class="mt-7"></div>
      <div class="mt-7"></div>
      <div class="mt-7"></div>
    </div>
  );
}

export default Kontakt;
