import { useState, useEffect } from 'react';

function useLocalStorage(key) {
  const [value, setValue] = useState(localStorage.getItem(key));

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === key) {
        setValue(e.newValue);
      }
    };

    let timeout;

    // Delay in milliseconds before re-attaching the event listener
    const delay = 500; // You can adjust this delay as needed.

    const handleDelayedStorageChange = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        window.removeEventListener('storage', handleStorageChange);
        window.addEventListener('storage', handleStorageChange);
      }, delay);
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearTimeout(timeout);
    };
  }, [key]);

  return value;
}

export default useLocalStorage;
