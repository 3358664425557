import React, { useEffect, useState } from "react";

const host = process.env.REACT_APP_BACKEND_HOST;
const port = process.env.REACT_APP_BACKEND_PORT;
const protocol = host==="localhost"?"http://":"https://";
const currentHost = `${protocol}${host}:${port}`;

console.log(currentHost);

const HüttenLogin = ({ onLoginSuccess }) => {
    const [name, setName] = useState('');
    const [passwort, setPasswort] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
   
    const handleLogin = async () => {
        try {
          const response = await fetch(currentHost + '/api/loginBuchung', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              nutzer: name,
              passwort: passwort,
            }),
          });
      
          if (response.status === 200) {
            console.log("token created - storing data...")
            const data = await response.json();
            const hüttenToken = data.hüttenToken;
            const role = data.role;
      
            localStorage.setItem('hüttenToken', hüttenToken);
            localStorage.setItem('role', role);
            console.log("data stored");

            onLoginSuccess();
          } else {
            setError('Login fehlgeschlagen');
          }
        } catch (error) {
            setError('Server Fehler. Bitte kommen Sie zu einem anderen Zeitpunkt zurück.');
        }
    };


  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Hüttenbelegung
          </h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="name" className="sr-only">
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                autoComplete="username"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label for="passwort" className="sr-only">
                Passwort
              </label>
              <div className="relative rounded-md shadow-sm">
            <input
              id="passwort"
              name="passwort"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              required
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder="Passwort"
              value={passwort}
              onChange={(e) => setPasswort(e.target.value)}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:text-indigo-700"
              >
                {showPassword ? 'Verbergen' : 'Zeigen'}
              </button>
            </div>
          </div>
            </div>
          </div>
          <div>
            <button
              onClick={handleLogin}
              type="button"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Anmelden
            </button>
          </div>
          {error && (
          <p className="mt-2 text-center text-sm text-red-600">{error}</p>
        )}
        </form>
      </div>
    </div>
  );
};

export default HüttenLogin;
